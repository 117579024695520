.checkout__page {
    display: flex;
    gap: 36px;
    width: 100%;
    background: #FEFEFE;

    padding: 16px 6vw;

    .disabled {
        cursor: not-allowed;
        opacity: .5;
    }

    .importantText {
        color: #cf0c0c !important;
        font-weight: 500 !important;
        font-size: 14px !important;
    }

    .checkout__section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;

        .section__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 32px;
            width: 100%;

            .section__heading {
                color: #18303E;
                font-size: 32px;
                font-weight: 600;
            }
        }

        .section__heading {
            color: #18303E;
            font-size: 24px;
            font-weight: 500;
        }

        .section__container {
            display: flex;
            align-items: center;
            /* padding: 40px; */
            gap: 20px;

            flex-direction: row;

            border-radius: 20px;
            background: var(--section-background-color);
            /* box-shadow: 9px 4px 136.9px 9px rgba(38, 50, 56, 0.10); */

            .container__detail {
                display: flex;
                justify-content: center;
                flex-direction: column;
                gap: 16px;
                padding: 40px;
                width: 100%;

                .radio {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    >span {
                        color: #BF3939;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }

                .paragraph__detail {
                    color: #5C7480;
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            .sec__type {
                justify-content: start;
                flex-direction: row;
                align-items: center;

                .radio {
                    align-items: start;
                    flex-direction: column;

                    input[type="radio"]::before {
                        border-radius: 50%;
                    }

                    .svg {

                        display: flex;
                        align-items: center;
                        gap: 10px;

                        >span {
                            color: #BF3939;
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }
                }

            }
        }
    }

    .selectors {
        display: flex;
        flex-direction: column;
        gap: 48px;

        width: 100%;
    }

    .checkout__cart {
        border-radius: 20px;
        background: var(--White-Color, #FFF);
        box-shadow: 0px -4px 16px 0px rgba(38, 50, 56, 0.04);

        overflow: hidden;

        width: 360px;
        display: flex !important;
        align-items: center;
        flex-direction: column;
        gap: 12px;

        height: fit-content;

        .header {
            display: flex;
            flex-direction: column !important;
            gap: 10px;

            padding: 20px;

            .coupon {
                color: var(--text-color, #18303E);
                font-family: Raleway;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                display: flex !important;
                align-items: center;

                >span {
                    color: var(--primary-color);
                }
            }

            .input__field {
                display: flex !important;
                align-items: center;
                gap: 10px;

                >input {
                    width: 100%;
                    padding: 5px 0;

                    font-size: 16px;

                    border: 0;
                    border-bottom: 1px solid var(--text-color, #18303E);
                }

                .btn {
                    font-size: 14px;

                    width: fit-content;
                    padding: 8px;
                }
            }

            .price__row {
                display: flex !important;
                align-items: center;
                justify-content: space-between;

                width: 100%;

                .price__head {
                    color: var(--text-color, #18303E);
                    font-family: Raleway;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .price {
                    color: var(--primary-color);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }

        .price__total {
            display: flex !important;
            align-items: center;
            justify-content: space-between;

            background: var(--primary-light, rgba(191, 57, 57, 0.10));

            width: 100%;
            padding: 16px 20px;

            .price__head {
                color: var(--text-color, #18303E);
                font-family: Raleway;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .price {
                color: var(--primary-color, #BF3939);
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

        }

        >.btn {
            margin: 0 20px 20px 20px;
        }
    }
}

.datePicker {
    cursor: pointer;
    width: fit-content;
    position: relative;

    .react-datepicker {
        top: 100%;
        left: 0;

        display: flex;

        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
            background-color: var(--primary-color);
        }

        .react-datepicker-time__header {
            color: var(--white-color);
        }

        .react-datepicker__day-name {
            color: var(--white-color);
        }

        .react-datepicker__header {
            background-color: var(--primary-color);
        }

        .react-datepicker__current-month {
            color: var(--white-color);
        }

        .react-datepicker__day--selected {
            background-color: var(--primary-color);
        }
    }
}

@media (max-width:1140px) {
    .checkout__page {
        flex-direction: column;

        .checkout__cart {
            width: 100%;

            .header {
                width: 100%;
            }
        }
    }
}

@media (max-width:884px) {
    .checkout__page {
        padding: 16px 6vw;

        .checkout__section {

            .section__heading {
                font-size: 28px;
            }

            .section__container {
                flex-direction: column;
                gap: 10px;

                .container__detail {
                    width: 100%;
                    padding: 20px;
                }
            }
        }
    }

    .checkout__page {
        .checkout__section {
            .section__container {
                .container__detail {
                    .radio {
                        >span {}
                    }
                }
            }
        }
    }

    .checkout__page {
        .checkout__section {
            .section__container {
                .sec__type {
                    .radio {
                        .svg {
                            >span {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    .checkout__page {
        .checkout__section {
            .section__container {
                .container__detail {
                    .paragraph__detail {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media (max-width:450px) {
    .datePicker {
        .react-datepicker {
            left: -30px;
            transform: scale(.85);
        }
    }
}