.box {
    -webkit-box-shadow: 0 0 10px #cecece;
    box-shadow: 0 0 10px #cecece;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.card {
    position: relative;
    border-radius: 12px;
    background-color: var(--card-background-color);

    -webkit-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

    cursor: pointer;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.card:hover {
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);
}

.rating {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: var(--primary-color);
    padding: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0 4px 20px rgb(241, 181, 90);
    box-shadow: 0 4px 20px rgb(241, 181, 90);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}

.rating i {
    color: hsl(44, 100%, 58%);
}

.rating span {
    font-size: var(--small-size);
    color: var(--secondary-color);
    opacity: .8;
}

.heart {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px 10px 6px 10px;
    border-radius: 50px;
    background-color: var(--primary-color);
    color: var(--dark-color);
    -webkit-box-shadow: var(--shadow-hover);
    box-shadow: var(--shadow-hover);
    z-index: 1;
}

.heart i {
    font-size: 20px;
}

.card__img {
    border-radius: 10px 10px 0px 0px;
    width: 120px;
    height: 120px;
    overflow: hidden;
    position: relative;

    >img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        /* border-radius: 10px; */
    }
}

.horizental__card__img {}

.vertical__card__img {
    border-radius: 10px 10px 0px 0px;
    width: 350px;
    height: 200px;
    overflow: hidden;
    position: relative;

    >img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
}

.title__price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    color: var(--card-product-title-color);
}

.menu__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;
    padding: 10px 10px 16px 10px;
}

.price {
    color: var(--primary-color);
    font-weight: var(--normal-font-weight);
    font-size: var(--normal-size);
}

.time {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}

.time i {
    color: var(--primary-color);
}

.time span {
    color: var(--secondary-color);
}

.food__type {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.type {
    background-color: var(--dark-color);
    padding: 8px 10px;
    border-radius: 10px;
    color: #8A8E9B;
}

.add {
    padding: 10px 16px;
    border-radius: 12px;
    background-color: var(--primary-color);
    color: var(--white-color);
    -webkit-box-shadow: var(--shadow-hover);
    box-shadow: var(--shadow-hover);
    position: absolute;
    bottom: -23px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    cursor: pointer;
}







@media (max-width:450px) {
    .title__price {
        gap: 8px !important;

        >h4 {
            font-weight: 400;
            font-size: 14px;
        }
    }
}










/* horizetal card */
/* horizetal card */
/* horizetal card */


.horizental__card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    background-color: var(--section-background-color);
    padding: 20px;
    border-radius: 10px;
    padding-right: 30px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;

    .horizental__card__img {
        width: 160px;
        height: 120px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;

        >img {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            width: 100%;
            border-radius: 10px;
        }
    }

    .horizentalcard__content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 20px;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;

        .basic__content {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: flex-start;
            -ms-flex-align: flex-start;
            align-items: flex-start;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            gap: 10px;

            .food__name {
                display: flex;
                align-items: center;
                gap: 20px;

                text-transform: capitalize;
                color: var(--primary-color);
                font-size: 24px;
                font-weight: 700;

                /* white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; */

                .deal__tag {
                    color: var(--dark-color);
                    font-size: 10px;
                    font-style: normal;
                    letter-spacing: 1px;
                    border-radius: 4px;
                    background: var(--primary-color);
                    padding: 2px 4px;
                }
            }
        }

        .card__price__heart {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: flex-end;
            -ms-flex-align: flex-end;
            align-items: flex-end;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            gap: 10px;

            .product__price {
                color: var(--primary-color);
                font-family: Poppins;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

                display: -webkit-box;

                display: -ms-flexbox;

                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                gap: 10px;

                >i {
                    padding: 2px 4px;
                    border: 2px solid var(--primary-color);
                    border-radius: 6px;
                    font-weight: 500;
                    cursor: pointer;
                    -webkit-transition: .3s;
                    -o-transition: .3s;
                    transition: .3s;
                }

                >i:hover {
                    -webkit-transform: translateY(-4px);
                    -ms-transform: translateY(-4px);
                    transform: translateY(-4px);
                }
            }
        }

    }
}

.horizental__card:hover {
    scale: 1.015;
}

.heart__ic {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: var(--primary-color);
    width: 32px;
    height: 32px;
    border-radius: 50%;

    position: absolute;
    z-index: 1;
    top: 4px;
    right: 0px;

    >i {
        font-size: 16px;
        color: var(--dark-color);
    }
}

.heart__ic.horizental-heart {
    z-index: 0;
}


/* mobile screen */
/* mobile screen */

@media (max-width:712px) {
    .horizental__card .horizentalcard__content .basic__content .food__name .deal__tag {
        top: 26px;
        padding: 4px 10px;
        left: 14px;
        border-radius: 20px 20px 0px 0px;
        position: absolute;
    }
}




@media (max-width: 560px) {
    .horizental__card__img {
        display: none;
    }


    .horizental__card .horizentalcard__content .basic__content .food__name .deal__tag {
        border-radius: 8px;
        top: auto;
        left: 10px;
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
        bottom: 10px;
    }
}

.horizentalcard__content {
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    position: relative;



    .basic__content {
        width: 100%;
    }

    .card__price__heart {
        width: 100%;
    }

}

.heart__ic {
    left: auto;
    right: 0px;
    top: 0px;
    bottom: auto;
    /* z-index: -1; */
}

/* mobile screen */
/* mobile screen */

@media (max-width:420px) {
    .card {
        overflow: hidden;
        width: 320px;

        >img {
            width: 100%;
        }
    }

    .horizental__card {
        .horizentalcard__content {
            .basic__content {
                .food__name {
                    max-width: 200px;
                }
            }
        }
    }
}

/* small mobile screen */
/* small mobile screen */

@media (max-width:320px) {
    .card {
        width: 260px;
    }

    .add {
        bottom: -16px;
        padding: 6px 10px;
        font-size: 14px;
    }

}