.payment__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    .section__heading {
        font-size: 32px;
        font-weight: 600;

        color: var(--text-color);
    }

    .btn {
        cursor: pointer;
        color: var(--white-color);
        font-weight: 400;
        font-size: 14px;
        padding: 6px 12px;
    }
}

.payment__contanier {
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    gap: 32px;

    width: 100%;

    .creadit__card {
        position: relative;
        border-radius: 14px;

        .delete__icon {
            z-index: 1;

            top: 14px;
            right: 14px;

            font-size: 22px;
            color: var(--white-color);
            position: absolute;
        }
    }

    .creadit__card.active__payment {
        .rccs {
            .rccs__card--unknown>div {
                border: 4px solid var(--primary-color);
                box-shadow: 0 4px 20px #BF393980;
            }
        }
    }
}

.mastercard--back .rccs__card--unknown>div,
.visa--back .rccs__card--unknown>div {
    background: transparent;
}

.visa--back {
    background-color: none;
    /* background: url('../../../../public/Images/CategoryTabImg/visa.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
}

.mastercard--back {
    background-color: none;
    /* background: url('../../../../public/Images/CategoryTabImg/mastercard.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
}

@media (max-width:520px) {
    .payment__header {
        .section__heading {
            font-size: 24px;
        }
    }
}