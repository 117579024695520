/* Snackbar.css */
.snackbar {
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 100px;
  right: 0px;
  background-color: var(--primary-color);
  color: var(--dark-color);
  padding: 16px 32px;
  border-radius: 40px 0 0 40px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s, transform .3s;
  transform: translateX(500px);
  z-index: 100;
}

.snackbar.show {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}