.goingtoparrow {
    background: var(--secondary-color);
    border-radius: 10px;
    width: fit-content;
    padding: 4px 6px;
    z-index: 3;
    position: fixed;
    bottom: 30px;
    right: 6vw;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 714px) {
    .goingtoparrow{
        z-index: 94;
        bottom: 140px;
    }
}