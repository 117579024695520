.complete{
 background-image: url('../../../../public/img/ordercompletaion.png');
 background-size: cover;
 background-repeat: no-repeat;
 height: 75vh;
 width: 100%;
 display: flex;
 flex-direction: column;
 gap: 20px;
 align-items: center;
 justify-content: center;
}

.tick i{
 color: var(--primary-color);
 padding: 10px 12px;
 font-size: 40px;
 border-radius: 50%;
 box-shadow: 0 4px 20px var(--primary-color);
}

.complete .subheading{
 text-align: center;
 font-size: 40px;
 color: var(--secondary-color);
 font-weight: 900;
}

.complete .paragraph{
 text-align: center;
}