.cart__section {
    padding: 30px 6vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 40px;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
}



/* order detail or .calculation */

.order__detail {
    -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    padding: 10px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 32px;
    min-height: 440px;
    position: relative;
    background: var(--dark-color);

    padding-bottom: 20px;
}

.cart__section .subheading {
    border-bottom: 1px solid var(--primary-color);
}

.calculation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 48px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.complete__changer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 32px;
}

.calculationheading {
    font-size: var(--normal-size);
    color: var(--secondary-color);
    font-weight: var(--normal-font-weight);
    text-align: center;
}

.calculate {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;
}

.subtotal,
.total {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.tab__changer {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 6px 1fr;
    grid-template-columns: 1fr 1fr;
    /* height: 64px; */
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    overflow: hidden;
    width: 100%;

    .exchange__tab {
        height: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        gap: 6px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: var(--primary-color);
        cursor: pointer;
        padding: 6px 10px;

        >i {
            font-size: 20px;
            color: var(--icon-size);
        }

        >span {
            color: var(--icon-size);
            font-size: 14px;
        }
    }

    .exchange__tab:nth-child(2) {
        color: gray;
    }

    .exchange__tab.activ {
        background-color: var(--primary-color);

        >i {
            font-size: 20px;
            color: var(--dark-color);
        }

        >span {
            color: var(--dark-color);
            font-size: 14px;
        }
    }

}

.cart__popup {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99;
    transform: translate(-50%, -50%);

    background: var(--white-color);
    width: 440px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 0px 5000px rgba(0, 0, 0, .4);

    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;

    .title {
        font-size: 32px;
    }

    .popup__inputs {

        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 10px;

        .relative.input>span {
            position: absolute;
            bottom: 15px;
            left: 12px;
        }

        .relative {
            position: relative;
        }

        .relative.input {
            >input {
                padding: 16px 16px 16px 50px;
            }
        }
    }
}

.subtotal span,
.total span {
    color: var(--secondary-color);
    font-size: var(--link-size);
    font-weight: var(--normal-font-weight);
}

.checkout {
    background-color: var(--primary-color);
    width: 101%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: absolute;
    left: -11px;
    bottom: 20px;
    color: var(--white-color);
    padding: 16px;
    border-radius: 10px 10px 0 0;
    color: var(--dark-color);
}

.items__price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 6px;
}

.checkout__btn {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    align-items: center;
    color: var(--dark-color);
    gap: 10px;
}

.checkout__btn i {
    transition: .3s;
    animation: forwardbackward 2s infinite;
    padding: 2px;
    border-radius: 50%;
    color: var(--secondary-color);
    background-color: var(--dark-color);
    font-size: var(--icon-size);
    -webkit-transition: var(--transition-3);
    -o-transition: var(--transition-3);
    transition: var(--transition-3);
}

.checkout__btn:hover i {
    -webkit-transform: translateX(6px);
    -ms-transform: translateX(6px);
    transform: translateX(6px);
}



.triangle-1 {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    position: absolute;
    width: 0;
    height: 0;
    bottom: 10px;
    right: -9.5px;
    border-top: 12px solid var(--primary-color);
    border-right: 9px solid transparent;
}

.triangle-2 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    position: absolute;
    width: 0;
    height: 0;
    bottom: 10px;
    left: -13px;
    border-top: 8px solid var(--primary-color);
    border-right: 12px solid transparent;
}




/* cart__items */
/* cart__items */

.cart__items {
    padding: 10px;
    width: 100%;
    max-height: 550px;
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 24px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    overflow-y: scroll;

    .paragraph {
        text-align: center;
    }
}

.cart__items .subheading {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    padding: 6px 0;
}

.cart__items .subheading i {
    color: var(--primary-color);
    padding: 4px;
    border: 1px solid var(--primary-color);
    border-radius: 50%;
}



.cart__item {
    width: 100%;
    border-radius: 10px;
    -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.cart__card__detail {
    position: relative;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    align-items: center;
    gap: 14px;

    width: 100%;
    border: 1px solid var(--section-background-color);
    /* box-shadow: 0 0px 10px 2px rgba(0, 0, 0, .04); */

    background-color: var(--section-background-color);
}

.cart__card__detail .card__img {
    height: 106px;
}

.cart__card__detail .card__img img {
    border-radius: 10px 0 0 10px;
    width: 106px;
    /* height: 100%; */
    object-fit: cover;
    /* border-radius: 10px 0 0 10px; */
}

.cart__card__detail .card__detail {
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    gap: 25px;
}


.card__detail .title__price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 24px;

    align-items: center;
}

.card__title {
    color: var(--secondary-color);
    line-height: 115%;
    letter-spacing: -0.48px;
    font-size: var(--icon-size);

    width: 180px;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
}

.card__price {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--secondary-color);
    line-height: 115%;
}

.total__price {
    position: absolute;
    top: 10px;
    right: 10px;
}

/* plus minus  */

.add__remove {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;

    .minus,
    .plus {
        >i {
            color: var(--dark-color) !important;
        }
    }

    .quantity span {
        color: var(--dark-color) !important;
    }
}

.cart__trash {
    cursor: pointer;
    color: var(--primary-color);
    padding-left: 5px;
}



.plus i {
    cursor: pointer;
    background-color: var(--primary-color);
    font-size: 12px;
    padding: 4px;
    color: var(--white-color);
    border-radius: 50%;
    -webkit-transition: var(--transition-3);
    -o-transition: var(--transition-3);
    transition: var(--transition-3);
    border-radius: 5px;
}

.minus i {
    cursor: pointer;
    background-color: var(--primary-color);
    font-size: 12px;
    padding: 4px;
    color: var(--white-color);
    border-radius: 50%;
    -webkit-transition: var(--transition-3);
    -o-transition: var(--transition-3);
    transition: var(--transition-3);
    border-radius: 5px
}

.plus i:hover {
    -webkit-box-shadow: 0 0px 10px var(--primary-color);
    box-shadow: 0 0px 10px var(--primary-color);
}

.minus i:hover {
    -webkit-box-shadow: 0 0px 10px var(--primary-color);
    box-shadow: 0 0px 10px var(--primary-color);
}

.detail__btn {
    border-radius: 8px;
    position: absolute;
    bottom: 6px;
    right: 92px;
    padding: 4px;
}

.detail__btn i {
    font-size: var(--icon-size);
}

/* card__accessories */

.card__accessories {
    border-top: 1px solid var(--primary-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px;

    width: 100%;
}

.accessoriy {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.acces__addrem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 30px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.acces__addrem>span {
    width: 150px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
    font-size: var(--medium-size);
    color: var(--secondary-color);

    >div .fa-xmark {
        cursor: pointer;
    }
}

.acces__addrem .plus i,
.acces__addrem .minus i {
    padding: 4px;
    font-size: 10px;
    border-radius: 5px;
}

.acces__addrem .plus i:hover,
.acces__addrem .minus i:hover {
    -webkit-box-shadow: 0 0px 4px var(--primary-color);
    box-shadow: 0 0px 4px var(--primary-color);
}

.accessories__price {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--secondary-color);
    line-height: 115%;
}

.radio {
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    color: var(--primary-color);
}

.radio label {
    border-radius: 50%;
    font-size: var(--normal-size);
}



.quantity {
    background-color: var(--primary-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
}

.quantity {
    font-size: 10px !important;
    color: var(--white-color) !important;
}



/* tab screen */
/* tab screen */
@media (max-width:1252px) {
    .cart__section {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .order__detail {
        min-height: 440px;
        width: 100%;
    }

    .cart__items {
        width: 100%;
    }

    .checkout {
        min-width: calc(100% + 22.35px);
        width: calc(100% + 22.35px);
    }

}


@media (max-width:792px) {
    .cart__card__detail {
        align-items: start;

        .card__price {
            height: fit-content;
            width: fit-content;

            bottom: 10px;
            top: auto;
            left: 132px;
        }

        .card__detail {
            gap: 6px;
            flex-direction: column;
        }
    }

    .cart__items {
        overflow: scroll;

        padding: 0;
    }

    .order__detail {
        box-shadow: none;
        padding: 0;
    }
}

.checkout__btn {
    width: 100%;
}

@media (max-width:470px) {

    .cart__popup {
        min-width: calc(100% - 40px);
        width: auto;
    }

    .cart__section{
        gap: 10px;

        
    }

}

@keyframes forwardbackward {
    0% {
        transform: translateX(-5px);
        opacity: .2;
    }

    50% {
        transform: translateX(5px);
        opacity: 1;
    }

    100% {
        transform: translateX(10px);
        opacity: 0;
    }
}

.size{
    width: 90px;
}

@media (max-width:695px) {
    .card__title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 160px;
    }

    .size {
        font-size: 14px;
    }

    .cart__card__detail {
        flex-direction: column;

        .card__img {
            width: 100%;
            height: 200px;
            position: relative;

            overflow: hidden;

            >img {
                width: 100%;
                height: auto;

                position: absolute;

                border-radius: 10px 10px 0 0;
            }
        }

        .card__detail {
            width: 100%;

            .total__price {
                left: auto;
                right: 10px;
            }
        }
    }
}