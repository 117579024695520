.calender__selector{
    position: relative;
}

.react-datepicker.custom-calendar-container {
    top: 60px;
    
    position: absolute;
    
    overflow: hidden;

    border: 1px solid var(--text-color);
    border-radius: 10px;

    .react-datepicker__header.react-datepicker__header--time,
    .react-datepicker__header.react-datepicker__header--has-time-select {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        min-height: 60px;
        background-color: transparent;

        border-bottom: 0px solid var(--light-color);

        .react-datepicker__current-month,
        .react-datepicker-time__header {
            color: var(--primary-color);
            font-size: 14px;
            font-weight: 600;
        }

        .react-datepicker__day-name {
            font-weight: 600;
            color: var(--text-color, #18303E);
        }
    }

    .react-datepicker__time-container{
        border-left: 1px solid var(--light-color);
    }
    
    .react-datepicker__time-list-item.react-datepicker__time-list-item--selected,
    .react-datepicker__day.react-datepicker__day--011.react-datepicker__day--selected.react-datepicker__day--today{
        background-color: var(--primary-color) !important;
    }
}