.mobile-prefix {
  color: red;
}

.mobile-input {
  display: flex;
  align-items: center;
}

.show-hide-button >i{
  color: var(--secondary-color);
  font-size: large;
  padding-right: 10px;
}

.relative__input {
  position: relative;
}

/* signup.css */

.facebook-login-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  gap: 12px;

  background-color: var(--dark-color);
  color: var(--primary-color);
  padding: 20px;

  >i {
    font-size: var(--icon-size);
    color: #316FF6;
  }
}

.number__country {
  position: absolute;
  color: var(--primary-color);
  bottom: 15px;
  left: 15px;
}

.phone__inputfield {
  >input {
    padding: 16px 16px 16px 54px;
  }
}

.hideshow {
  position: absolute;
  bottom: 16px;
  right: 10px;
  cursor: pointer;
}

.validation-error {
  color: red;
}

@media (max-width: 1048px) {
  .hideshow {
    bottom: 9px;
  }
}