.gallery__section {

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 44px;
    padding: 44px;

    border-radius: 24px;
    background: var(--solid-black-5, #F2F2F2);

    width: 100%;

    .gallery__container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 24px;

        width: 100%;

        height: 660px;

        .gallery__image {
            border-radius: 16px;
            overflow: hidden;

            position: relative;

            .img {
                position: absolute;
                object-fit: cover;
                height: 100%;
            }
        }

        .gallery__image:first-child {
            grid-column: 1/3;
            grid-row: 1/3;

            .img {
                width: 100%;
                height: 100%;
            }
        }

        .gallery__image:nth-child(2) {
            grid-column: 3/4;

            .img {
                width: 100%;
            }
        }
    }
}

@media (max-width:1293px) {
    .gallery__section {
        width: 100%;

        .gallery__container {
            gap: 8px;
            height: 400px;
        }

        .gallery__container {
            .gallery__image:nth-child(1) {
                .img {
                    width: 100%;
                }
            }

            .gallery__image:nth-child(2) {
                .img {
                    width: 100%;
                }
            }

            .gallery__image:nth-child(3) {
                .img {
                    width: 100%;
                }
            }

            .gallery__image:nth-child(4) {
                .img {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width:1040px) {
    .gallery__section {
        border-radius: 0px;
        padding: 32px 24px;
        max-width: 100%;
    }
}

@media (max-width:900px) {
    .gallery__section {
        padding: 32px 24px;
        max-width: 100%;

        .gallery__container {
            height: 660px;
            grid-template-rows: repeat(4, 1fr);


            .gallery__image:nth-child(1) {
                grid-column: 1/5;
                grid-row: 1/3;

                .img {
                }
            }

            .gallery__image:nth-child(3) {
                grid-column: 1/3;
                grid-row: 3/5;
            }

            .gallery__image:nth-child(4) {
                grid-column: 3/5;
                grid-row: 3/4;
            }
        }
    }
}

@media (max-width:686px) {
    .gallery__section {
        padding: 24px 16px;

        .gallery__container {
            height: 500px;
        }
    }
}