
.gallery__hotel .swiper-button-prev:after,
.gallery__hotel .swiper-button-next:after {
    font-size: 20px;
    color: var(--solid-black-100);
}

.gallery__hotel .swiper-button-prev.swiper-button-disabled:after,
.gallery__hotel .swiper-button-next.swiper-button-disabled:after {
    font-size: 20px;
    color: var(--opacity-white-100);
}

.gallery__hotel .swiper-button-prev.swiper-button-disabled,
.gallery__hotel .swiper-button-next.swiper-button-disabled {
    width: 48px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid var(--opacity-white-100);
    box-shadow: 0px 1px 2px 0px rgba(31, 41, 55, 0.08);
    opacity: 1;
    background-color: transparent;
}

.gallery__hotel .swiper-button-prev,
.gallery__hotel .swiper-button-next{
    display: flex;
    align-items: center;
    justify-content: center;
    
    width: 48px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid var(--opacity-white-100);
    box-shadow: 0px 1px 2px 0px rgba(31, 41, 55, 0.08);
    background-color: var(--opacity-white-100);
    font-weight: 600;
    opacity: 1;
}

.gallery__hotel .swiper-slide-thumb-active{
    border: 2px solid red;
    opacity: 1;
}