.nav__bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 16px 6vw;
    top: 0;
    background: var(--navbar-background-color);
}




/* logo */
/* logo */
.logo {
    cursor: pointer;

    >img {
        width: 80px;
    }
}

.logout{
    cursor: pointer;
}


/* nav links */
/* nav links */

.nav__links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 3vw;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.link {
    font-weight: var(--normal-font-weight);
    color: var(--navbar-link-color);
    -webkit-transition: var(--transition-3);
    -o-transition: var(--transition-3);
    transition: var(--transition-3);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.link:hover {
    color: var(--navbar-active-link-color);
}

.active_icon {
    position: relative;
    color: var(--navbar-active-link-color) !important;
}

.active {
    background-color: var(--primary-color);
    border: 0;
    color: var(--dark-color);
}

.none {
    display: none;
}

.icon__none {
    display: none;
}

.icon-count {
    position: absolute;
    background: var(--navbar-count-background-color);
    padding: 5px 8px;
    line-height: 10px;
    border-radius: 50%;
    color: var(--navbar-count-text-color);
    font-size: 12px;
    top: -10px;
    right: -14px;
    -webkit-box-shadow: var(--shadow-hover);
    box-shadow: var(--shadow-hover);
}



/* buttons */
/* buttons */

.nav__icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 30px;
}

.cart__icon {
    cursor: pointer;
    font-size: var(--icon-size);
    color: var(--navbar-icon-color);
    -webkit-transition: var(--transition-3);
    -o-transition: var(--transition-3);
    transition: var(--transition-3);
}

.cart__icon:hover {
    color: var(--navbar-active-icon-color);
}

.cart__active {
    color: var(--navbar-active-icon-color);
}

.language__icon {
    font-size: 36px;
}





/* menu icon*/

.menu {
    cursor: pointer;
    background-color: var(--navbar-menu-background-color);
    -webkit-box-shadow: var(--shadow-hover);
    box-shadow: var(--shadow-hover);
    border-radius: 6px;
    padding: 10px;
    z-index: 999;
    display: none;
    right: 30px;
    top: 86px;

    align-items: center;
    justify-content: center;
}

.menu i {
    font-size: var(--icon-size);
    color: var(--navbar-menu-icon-color);
    cursor: pointer;
}

.user__icon {
    position: relative;
    color: #323643;
}

.user__drop {
    -webkit-animation: top-bottom .3s 1 ease-in-out;
    animation: top-bottom .3s 1 ease-in-out;
    -webkit-transition: var(--transition-3);
    -o-transition: var(--transition-3);
    transition: var(--transition-3);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 0;
    right: 0;
    top: 40px;
    width: fit-content;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 4px;
    flex-direction: column;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    gap: 10px;
    position: absolute;
    border-radius: 6px;
    z-index: 999;
    background: var(--dark-color);
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, .3);
}

@-webkit-keyframes top-bottom {
    0% {
        opacity: .2;
        -webkit-transform: translatey(-6px);
        transform: translatey(-6px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translatey(0px);
        transform: translatey(0px);
    }
}

@keyframes top-bottom {
    0% {
        opacity: .2;
        -webkit-transform: translatey(-6px);
        transform: translatey(-6px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translatey(0px);
        transform: translatey(0px);
    }
}

.user__drop .span {
    color: var(--secondary-color, #323643);
    font-family: 'Poppins';
    font-size: 14px;
    width: 180px;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    -webkit-transition: var(--transition-3);
    -o-transition: var(--transition-3);
    transition: var(--transition-3);
    padding: 8px 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;

    >span:first {
        width: 14px;
    }
}

.user__drop .span:hover {
    color: var(--dark-color) !important;
    background: var(--stroke, var(--primary-color));
}

.user__drop__active {
    color: var(--dark-color) !important;
    background: var(--stroke, var(--primary-color));
}

.porfile__img {
    border-radius: 50px;
    border: 2px solid var(--primary-color);
    -webkit-box-shadow: 0px 0px 13px 0px rgba(254, 114, 76, 0.20);
    box-shadow: 0px 0px 13px 0px rgba(254, 114, 76, 0.20);
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-transition: var(--transition-3);
    -o-transition: var(--transition-3);
    transition: var(--transition-3);
}

.ready__order {
    border-radius: 50px;
    border: 2px solid #157FFF;
    -webkit-box-shadow: 0px 0px 17px 0px rgba(21, 127, 255, 0.46);
    box-shadow: 0px 0px 17px 0px rgba(21, 127, 255, 0.46);
}

.profile__igm:hover .profile__order {
    opacity: 1;
}

.profile__order {
    text-transform: capitalize;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    border-radius: 10px 0px 10px 10px;
    background: var(--white-color);
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);
    position: absolute;
    bottom: -15px;
    right: 30px;
    color: #157FFF;
    font-family: Poppins;
    font-size: 12px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 22.4px;
    min-width: 130px;
    padding: 0 10px;
    opacity: 0;
}

.profile__igm {
    position: relative;
}

.qr__bar {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 64px;

    display: none;

    position: fixed;
    bottom: 0;
    z-index: 99;
    width: 100%;

    background-color: var(--qr-bar-background);
    padding: 20px 0;

    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2);

    >a {
        font-size: 20px;
        color: var(--qr-bar-icon-color);
    }

    .active__icon {
        color: var(--primary-color);
    }
}

@media (min-width:714px) {

    .link__lang {
        display: none;
    }

    .menu__icon {
        display: flex;
    }
}

@media (max-width:714px) {
    .icon__lang {
        display: none;
    }

    .qr__bar {
        display: flex;
    }

    .user__drop {
        bottom: 80px;
        top: auto;
    }

    .nav__bar {

        .nav__icons,
        .porfile__img {
            display: none;
        }
    }

    .menu {
        display: block;
        /* position: fixed; */
        top: 36px;
        right: 30px;
    }

    .nav__links {
        z-index: 999;
        width: 100%;
        height: 100%;
        background-color: var(--white-color);
        position: fixed;
        top: 50%;
        left: -60%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-transition: var(--transition-3);
        -o-transition: var(--transition-3);
        transition: var(--transition-3);
        -webkit-box-shadow: var(--shadow-hover);
        box-shadow: var(--shadow-hover);
    }


    .active__navbar {
        background-color: var(--dark-color);
        left: 50%;
    }

    .icon__none {
        display: block;
    }

}

@media (max-width:442px) {
    .nav__icons {
        gap: 20px;
    }

    .qr__bar {
        gap: 48px;
    }

}

@media (max-width:370px) {
    .nav__icons {
        gap: 16px;
    }

    .nav__bar .btn {
        display: none;
    }

    .none {
        display: block;
    }

}