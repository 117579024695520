.preSetToggle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  min-width: 400px;
}

.preSetToggle_btn {
  cursor: pointer;
  background-color: transparent;
  border: 1px solid var(--primary-color);
  padding: 10px 26px;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  transition: var(--transition-3);
  width: fit-content;
  color: var(--primary-color);
}

.preSetToggle_btn_active {
  background-color: var(--primary-color);
  color: var(--dark-color);
}

.disabled-sunday {
  pointer-events: none; /* Disable pointer events on Sundays */
  opacity: 0.5;
}
.preSetToggle_btn_Unactive {
  background-color: var(--background-color);
  color: var(--dark-color);
  border: 1px solid var(--dark-color);
}

.preSetOrder {
  min-width: 100%;
  position: relative;

  >i {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);

    font-size: 24px;

    color: var(--primary-color);
    
  }
}

.preSetOrder_filed {
  width: 100%;
  padding: 16px;
  color: var(--text-white);
  border: 1px solid var(--primary-color);
  border-radius: 8px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {

  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
}

.react-datepicker__input-container label {
  color: var(--text-white);
  font-size: var(--medium-size);
  font-weight: var(--normal-font-weight);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.react-datepicker__input-container input {
  background-color: var(--dark-color);
  width: 100%;
  color: var(--primary-color);
  border: 1.2px solid var(--primary-color);
  padding: 16px 20px;
  border-radius: 10px;
  outline: 0;
}

.react-datepicker__input-container input::-webkit-input-placeholder {
  color: var(--primary-color);
}

.react-datepicker__input-container input::-moz-placeholder {
  color: var(--primary-color);
}

.react-datepicker__input-container input:-ms-input-placeholder {
  color: var(--primary-color);
}

.react-datepicker__input-container input::-ms-input-placeholder {
  color: var(--primary-color);
}

.react-datepicker__input-container input::placeholder {
  color: var(--primary-color);
}

.react-datepicker__input-container input:focus {
  border: 2px solid var(--primary-color);
}


@media (max-width: 1075px) {
  .preSetOrder {
    width: 100%;
  }

  .preSetToggle {
    width: 100%;
  }
}




/* Custom date picker input styles */
.custom-datepicker-input {
  /* Add styles for the input here */
  background-color: yellow;
  /* Set the background color to yellow */
  border: 1px solid yellow;
  /* Set the border color to yellow */
  color: black;
  /* Set the text color */
  /* Add any other necessary styles */
}

/* Custom calendar container styles */
.custom-calendar-container {
  z-index: 2;
  position: absolute !important;
}

/* Custom calendar styles */
.custom-calendar {
  /* Add your styles here */
}

/* Custom day styles */
.sunday {
  /* Styles for Sundays */
}

.text{
  font-size: 20px;
}