.about__section{
    padding: 100px 6vw;
    display: flex;
    justify-content: center;
    gap: 6vw;
    align-items: center;
}

.about__img{
    max-width: 450px;
}

.about__img img{
    width: 100%;
}

.about__content{
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.about__section .subheading{
    color: var(--primary-color);
    font-size: 42px;
}



@media (max-width:890px) {
    .about__section{
        flex-direction: column;
    }

    .about__content{
        align-items: center;
    }

    .about__section .paragraph{
        text-align: center;
    }

}

@media (max-width:380px) {
    
    .about__section .subheading{
        font-size: 36px;
        text-align: center;
    }

}