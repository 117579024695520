.contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    padding: 30px 8vw;
}

.contact__detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 600px;
    width: 100%;
    background-color: var(--primary-color);
    color: var(--dark-color);
    padding: 30px;
    border-radius: 10px;

    .contact__heading {
        color: var(--dark-color);
    }
    
    .paragraph{
        color: var(--dark-color);
    }
}

.contact__information {
    display: flex;
    gap: 40px;
    flex-direction: column;
}

.info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.info .contact {
    padding: 0;
}

.contact__icon {
    display: flex;
    gap: 20px;
    color: var(--dark-color);
}

.contact__icon>a>i {
    font-size: 24px;
    color: var(--dark-color);
}

.contact__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.contact__form .form__input {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .input{
        >label{
            color: var(--text-white);
        }
    }
}


@media (max-width: 850px) {
    .contact {
        flex-direction: column-reverse;
    }
}