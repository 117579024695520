.delete__popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    box-shadow: 0px 4px 72px 0px rgba(255, 255, 255, 0.1);
    z-index: 2;

    background-color: var(--dark-color);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    box-shadow: 0 4px 0px 5000px rgba(0, 0, 0, .6);

    .delete__top {
        display: flex;
        align-items: center;
        justify-content: center;


        >i {
            font-size: 32px;
            color: var(--primary-color);
        }

        .heading {
            color: var(--primary-color);
            font-weight: 600;
        }
    }

    .btns {
        width: 100%;

        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }

        .sec__btn,
        .btn:last-child {

            color: var(--primary-color);
            border: 1px solid var(--primary-color);
        }
    }
}