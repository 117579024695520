.profile__page {
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding: 30px 6vw;
}

.profile__tab {
    display: flex;
    gap: 30px;
}

.tab {
    color: var(--secondry-color);
    padding: 8px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-bottom: 2px solid var(--secondry-color);
}

.active__tab {
    font-weight: 700;
    border-bottom: 2px solid var(--primary-color);
    color: var(--primary-color);
}

.profile__section {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 60px;
    border-radius: 10px;
    box-shadow: 0 0px 20px rgba(0, 0, 70, 0.08);
    background: var(--dark-color);
}

.input__sections {
    display: flex;
    gap: 30px;
    align-items: flex-start;
}

.section {
    width: 100%;
}

.mt-10 {
    margin-top: 10px;
}

.sec-sec {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.profile__section .title {
    text-align: flex-start;
    color: var(--primary-color);
    font-family: Poppins;
    font-size: 38px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

/* input field has been edited */

.profile__section .input label {
    display: block;
}

.input label span {
    color: #AD0000;
}

.profile__page .inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 26px;
}

.profile__page .inputs {
    border-top: 2px solid var(--secondry-color);
}

.profile__section .input {
    width: 48%;
}

.image__field {
    margin-bottom: 10px;
    background-size: cover;
    position: relative;
    width: 150px;
    height: 130px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    transition: .3s;
}

.image__field:hover i {
    opacity: 1;
}

.image__field:hover {
    border-radius: 15px;
    background: linear-gradient(rgba(0, 0, 0, 0.20));
}

.image__field img {
    transition: .3s;
    width: 100%;
}

.image__field i {
    background-color: #00000050;
    transition: .3s;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    font-size: 28px;
    position: absolute;
    color: #ffffff;
    opacity: 0;
}

.btns {
    justify-content: center;
    display: flex;
    gap: 10px;
    align-items: center;

    .btn {
        border: 1px solid var(--primary-color);
    }
}

.btns .btn:nth-child(2) {
    background-color: transparent;
    border: 1px solid #BAC0C2;
    color: var(--btn-text-color-2);
}

.input select {
    padding-right: 10px;
    width: 100%;
    color: var(--secondry-color);
    font-size: var(--medium-size);
    font-weight: var(--normal-font-weight);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: var(--primary-color);
    border: 1.2px solid var(--primary-color);
    padding: 16px 20px;
    border-radius: 10px;
    outline: 0;
}

.input option {
    font-weight: 300;
}

.input select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('../../../../public/img/arrow.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    padding-right: 30px;
}

.file__upload {
    position: absolute;
    opacity: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    cursor: pointer;
}


.input {
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 6px;

    >label {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    >input {
        padding: 16px;
        border-radius: 10px;

        outline: none;

        border: 1px solid var(--primary-color);
    }
}

/* table */

.table {
    border-radius: 10px;
    max-height: 200px;
    overflow: hidden;
    border: 1px solid var(--primary-color);
}

.table>span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: var(--white-color);
    padding: 10px;
    background-color: var(--primary-color);
    font-size: 22px;
    font-weight: 500;
    padding: 10px;
}

.table>span i {
    cursor: pointer;
    margin-right: 6px;
}

.address__table {
    overflow: scroll;
    max-height: 240px;
}

.table table {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin: 2px 0;
    max-height: 240px;
}

.table table>span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px;
    transition: .3s;
    cursor: pointer;
}



.table table>span:hover {
    color: var(--white-color);
    background-color: var(--primary-color);
}

.table table .active__address {
    color: var(--white-color);
    background-color: var(--primary-color);
}

.empty__history {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    background-color: var(--text-white);

    >img {
        width: 100%;
    }
}

.empty__alert {
    width: 100%;
}

@media (max-width: 828px) {
    .input__sections {
        flex-direction: column;
    }

    .profile__section .input {
        width: 100%;
    }

    .tab {
        font-size: 16px;
    }

    .profile__tab {
        flex-wrap: wrap;
        gap: 10px;
    }

    .profile__section {
        padding: 10px;
    }

    .order__history__search {
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .tab {
        padding: 6px 0px !important;
    }

    .tab::-webkit-scrollbar {
        height: 6px !important;
    }
}