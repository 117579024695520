.rccs {
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment__method {

    z-index: 999;
    width: 600px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;

    display: flex;
    flex-direction: column;

    gap: 20px;

    padding: 16px;
    border-radius: 10px;


    .payment__form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;

        >form {
            display: flex !important;
            flex-direction: column !important;
            gap: 10px;
        }

        .input {
            width: 100%;
        }
    }

    .btns {
        width: 100%;
        grid-column: 1;
        grid-column-end: 3;
    }

    .small__heading {
        font-weight: 500;
        color: var(--primary-color);
    }

}

.full__width {
    grid-column: 1;
    grid-column-end: 3;
}

.payemnt__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;

    >i{
        font-size: 32px;
        color: var(--primary-color);
        font-weight: 800;

        transition: .3s;

        cursor: pointer;
    }
    
    >i:hover{
        color: var(--secondary-color);
    }
    
    .small__heading{
        color: var(--secondary-color);
        font-size: 24px;
        font-weight: 500;
    }
}

.payment__card {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    position: relative;

    min-width: 100%;

    .rccs {
        transform: scale(0.5);
    }

    padding: 10px;
    border-radius: 10px;
    width: fit-content;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    cursor: pointer;

    .card__name {
        font-size: 20px;
        font-weight: 600;
        color: var(--primary-color);
        text-transform: capitalize;
    }

    .card__number {
        font-weight: 500;
        color: var(--secondary-color);
    }

    .edit__icon,
    .delete__icon {
        font-size: 18px;
        position: absolute;
        right: 10px;
        top: 10px;
        color: var(--primary-color);

        cursor: pointer;
    }

    .edit__icon {
        right: 40px;
    }

    >img {

        border-radius: 8px;

        height: 100%;
        width: 100px;
    }

    .payment__content {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .default {
        background: #fff;

        padding: 2px 4px;
        border-radius: 4px;

        position: absolute;
        top: 10px;
        right: 40px;

        color: var(--primary-color);
        font-weight: 500;
        font-size: 14px;
    }
}

.payment__card.active {
    background-color: var(--primary-color);


    .edit__icon,
    .delete__icon {
        color: var(--strok-color);
    }

    .card__number,
    .card__name {
        color: var(--strok-color);
    }

}

.payment__table.table {
    border: 0;

    .creadit__card {

        position: relative;
        cursor: pointer;
        
        .rccs {
            margin: 0;
        }

        .delete__icon{
            color: var(--white-color);
            font-size: 24px;
            position: absolute;
            right: 10px;
            top: 10px;

            
            z-index: 1;
        }
    }

    .creadit__card {
        .rccs {
            .rccs__card--unknown > div  {
                transition: .3s;
            }
        }
    }

    .creadit__card.active__payment {
        .rccs {
            .rccs__card--unknown > div  {
                background: var(--primary-color) !important;
            }
        }
    }
}

.payment__table .payment__contanier {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;
    flex-direction: row !important;
    overflow: visible;
    padding: 12px;
    gap: 32px;

    overflow-y: scroll;
}

.payment__table {
    width: 100%;
    max-height: fit-content;
}

@media (max-width: 900px) {

    .payment__card {
        min-width: 100%;
    }

    .payment__method {
        width: 350px;

        .payment__form {
            grid-template-columns: repeat(1, 1fr);
        }
    }

}