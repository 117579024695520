.sign__up {
    color: var(--text-white);
}

input[type="radio"],
input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--form-background);
    margin: 0;

    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    position: relative;

    outline: none;

    display: grid;
    place-content: center;
}

input[type="radio"]::before,
input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    background-color: var(--primary-color);

    position: absolute;
    left: 50%;
    top: 50%;
}

input[type="radio"]:checked::before,
input[type="checkbox"]:checked::before {
    transform: scale(1) translate(-50%, -50%);

}

input[type="radio"]:focus,
input[type="checkbox"]:focus {
    outline: none;
}

input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
    --form-control-color: var(--form-control-disabled);

    color: var(--form-control-disabled);
    cursor: not-allowed;
}


input[type="radio"],
input[type="checkbox"] {
    border: 1px solid var(--primary-color);
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid var(--primary-color);
    border-radius: 3px;
    outline: none;
}

















.popup__card {

    /* Layout */

    transform: scale(1.2);

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 32px;
    z-index: 99;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    /* Style */

    animation: popup .5s 1 linear;
    border-radius: 10px;
    background: var(--popup-background-color);
    box-shadow: 0px 4px 72px 0px rgba(0, 0, 0, .1);

    .product__detail {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .popup__title {
        color: var(--text-white);
        font-family: 'Poppins';
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .product__size {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 12px;

        .size__button {

            /* Layout */

            padding: 10px 20px;

            /* Style */

            cursor: pointer;

            border-radius: 10px;
            background: var(--section-background-color);

            color: var(--text-color, #18303E);
            font-family: 'Poppins';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .size__button.active {
            background: var(--primary-color);
            color: var(--dark-color) !important;
        }

        .size__button:empty {
            display: none;
        }
    }

    .popup__details {
        display: flex;
        align-items: flex-start;
        gap: 20px;

        .accessories__list,
        .price__adder {

            /* Layout */

            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            position: relative;
            min-width: 150px;

            /* Style */

            border-radius: 10px;
            background: var(--section-background-color);

            .adder__title {
                position: absolute;
                top: -18px;
                left: -16px;
                display: flex;
                align-items: center;
                gap: 2px;
                border-radius: 4px 27px 27px 4px;
                background: var(--dark-color);
                box-shadow: 0px 4px 17px 0px rgba(133, 133, 133, 0.2);

                padding: 0 10px 0 0;

                .adder__text {
                    user-select: none;
                    color: var(--primary-color);
                    font-family: 'Poppins';
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

            }

            .accessories__li {
                display: flex;
                min-width: 330px;
                justify-content: space-between;
                align-items: center;

                .accessories__name {

                    /* Layout */

                    user-select: none;
                    width: 140px;

                    /* Style */

                    color: var(--text-white);
                    font-family: 'Poppins';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                }

                .price__text {
                    user-select: none;
                    width: 60px;
                    color: var(--text-white);
                }

                .plus__minus {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    user-select: none;

                    .plus,
                    .minus {
                        user-select: none;

                        /* Layout */

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 20px;
                        height: 20px;

                        /* Style */

                        cursor: pointer;

                        border-radius: 29px;
                        background: var(--primary-color);
                        box-shadow: 0px 0px 16px 0px rgba(38, 50, 56, 0.10);
                    }

                    .number {
                        user-select: none;

                        /* Layout */

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 20px;
                        height: 20px;

                        /* Style */

                        user-select: none;

                        border-radius: 6px;
                        background: var(--background-color);
                        box-shadow: 0px 0px 16px 0px rgba(38, 50, 56, 0.25);

                        color: var(--primary-color);
                        text-align: center;
                        font-family: 'Poppins';
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }
            }
        }

        .price__adder {
            .accessories__li {
                min-width: 200px;
            }
        }

        .no__accessories {
            box-shadow: 0px 0px 16px 0px rgba(38, 50, 56, 0.1);

            .accessories__li {
                min-width: 330px;
            }
        }
    }

    .btns {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;

        .btn {

            /* Layout */

            width: 100%;
            display: flex;
            justify-content: center;

            /* Style */

            user-select: none;
            color: var(--dark-color);
            font-family: 'Poppins';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            padding: 12px 16px;

            border-radius: 8px;
            background: var(--primary-color);
        }

        .btn:last-child {
            color: var(--primary-color) !important;
            font-family: 'Poppins';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            background-color: transparent;

            border-radius: 10px;
            border: 1px solid var(--primary-color);
        }
    }
}

/* Component */

/* price text */

.price__text {
    color: var(--text-white);
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: flex-end;

    >span {
        color: var(--text-white);
        text-align: right;
        font-family: 'Poppins';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}












@media (max-width: 820px) {

    .popup__card {
        scale: 1;
        z-index: 95;

        .popup__details {
            flex-direction: column;
            gap: 32px;

            .accessories__list,
            .price__adder {
                width: 100%;

                .accessories__li {
                    min-width: 280px;

                    .accessories__name {
                        width: 110px;
                    }
                }
            }
        }
    }

}

@media (max-width: 432px) {

    .popup__card {
        scale: 1;
        padding: 8px;

        gap: 4px;

        max-height: 500px;
        overflow-y: scroll;


        .popup__details {

            .accessories__list,
            .price__adder {
                .accessories__li {
                    min-width: 260px;
                }

                .adder__title{
                    left: 0;
                }
            }

            .accessories__list {
                max-height: 200px;
            }
        }

        .btns {
            bottom: 10px;
            position: sticky;

            .btn {
                font-size: 14px;
            }

            .btn:last-child {
                background-color: #fff;
            }
        }
    }
    
    .popup__card::-webkit-scrollbar {
        width: 4px;
    }

}

@keyframes popup {
    0% {
        opacity: 0;
        transform: scale(0), translate(-60%, -50%);
    }

    100% {
        transform: scale(1.2), translate(-50%, -50%);
    }
}











.suggested__cards-slider {
    overflow: hidden;
}

.suggested__cards {
    display: flex;
    align-items: start;
    gap: 24px;
    border-radius: 10px;
    width: 100%;
    padding: 4px;
    position: relative;


    .suggested__card {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 150px;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

        .suggested__img {
            width: 100%;
            height: 100px;
            overflow: hidden;
            border-radius: 10px;
            width: 130px;

            >img {
                width: 100%;
                height: 100%;
            }
        }

        .card__button__price {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            >.btn {
                padding: 6px;
                font-size: 16px;
            }
        }
    }
}

.main__slider__suggested {
    position: relative;
}

.suggested__arrows {
    z-index: 99;
    background-color: #fff;
    position: absolute;
    width: fit-content;
    padding: 10px 16px;
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, .2);
}

.suggested__cards-next-button {
    bottom: 50%;
    transform: translateY(50%);
    right: -16px;
}

.suggested__cards-prev-button {
    bottom: 50%;
    transform: translateY(50%);
    left: -16px;
}