.footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 5vw;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 30px 6vw;

    background-color: var(--footer-background-color);
}

.footer__linksection {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 5vw;
}

.footer__logo {
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 400px;
    gap: 20px;

    .paragraph{
        color: var(--footer-paragraph-color);
    }
}

.footer__logo img {
    width: var(--footer-logo-width);
}

.footer__logo .footer__icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}

.footer__logo .footer__icons i {
    font-size: var(--footer-social-icon-size);
    color: var(--footer-social-icon-color);
}

.footer__links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;
    
    .active {
        padding: 0 6px;
        border-radius: 6px;
        color: var(--dark-color) !important;
    }
}

.footer__linkheading {
    font-size: var(--footer-link-heading-size);
    font-weight: var(--bold-font-weight);
    color: var(--secondary-color);
}

.footer__link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: var(--secondary-color);
    font-size: var(--footer-link-size);
    font-weight: var(--normal-font-weight);
    -webkit-transition: var(--transition-3);
    -o-transition: var(--transition-3);
    transition: var(--transition-3);
}

.footer__links:last-child .footer__link {
    width: 260px;

    >span {
        text-align: start;
    }
}

.footer__link:hover {
    color: var(--primary-color);
}

.copy {
    display: flex;
    justify-content: center;
    padding: 0 20px 20px 20px;

    .link {
        text-align: center;
    }
}


/* tab screens */
/* tab screens */

@media (max-width:1148px) {
    .footer {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .footer__logo {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .footer__linksection {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

}


/* mobile screen */
/* mobile screen */

@media (max-width:516px) {
    .footer__linksection {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}
@media (max-width:1083px) {
    .footer{
        margin-bottom: 40px;
    }
}
@media (max-width:714px) {
    .footer{
        margin-bottom: 70px;
    }
}