.gdpr-container {
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.4);
  bottom: 1em;
  right: 1em;
  position: fixed;
  height: fit-content;
  width: 300px;
  background-color: var(--primary-color);
  color: #fff !important;
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
  border-radius: 10px;
  z-index: 1000;
  div{
    .a {
      text-align: right;
      color: var(--secondary-color)
    }
    .button-container {
      margin-top: 10px;
      text-align: right;
      color: var(--light-color);
      .button {
        text-align: right;
        color: var(--light-color);
        background-color: var(--secondary-color);
      }
    }
  }
}