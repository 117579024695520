.mobile-prefix {
  color: red;
}

.mobile-input {
  display: flex;
  align-items: center;
}

.show-hide-button>i {
  color: var(--secondary-color);
  font-size: large;
  padding-right: 10px;
}

.relative__input {
  position: relative;
}

.hideshow {
  position: absolute;
  bottom: 16px;
  right: 10px;
  cursor: pointer;

  >i {
    color: var(--secondary-color);
  }
}

.validation-error {
  color: var(--red-note);
}

@media (max-width: 1048px) {
  .hideshow {
    bottom: 9px;
  }
}

@media (max-width:850px) {
  .login__title{
    font-size: 25px !important;
  }
}