.menu__section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 30px;
    padding: var(--section-padding);
}

.category__filter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 30px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.categories {
    padding: 8px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
    max-width: calc(100%-200px);
    overflow-x: scroll;
}

.categories::before {
    content: '';
    position: absolute;
    right: 0px;
    width: 60px;
    height: 40px;
}

.categories::-webkit-scrollbar {
    height: 6px;
}

.categories::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.active__categary {
    color: var(--white-color) !important;
    background-color: var(--primary-color) !important;
}

.category {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: var(--white-color);
    color: var(--secondry-color);
    padding: 6px 12px;
    border-radius: 8px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    -webkit-transition: var(--transition-3);
    -o-transition: var(--transition-3);
    transition: var(--transition-3);
}


.category:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
}

.active__slider { 
    background-color: var(--primary-color);
    opacity: 1;
    color: var(--white-color) !important;
}

.sort__filter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    width: fit-content;

    >span{
        color: var(--filter-text-color);
    }
}

.optionS:hover {
    background-color: var(--primary-color);
}

.filter {
    outline: none;
    min-width: 140px;
    position: relative;
    gap: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid var(--primary-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: var(--primary-color);
    cursor: pointer;
    padding: 4px 18px 4px 10px;
    background-color: var(--dark-color);
    min-height: 40px;
}

.filter {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('../../../../public/img/arrow.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    padding-right: 30px;
}


.sort {
    color: var(--secondry-color);
    font-weight: var(--normal-font-weight);
}

.selected:hover i {
    -webkit-transform: translateY(3px);
    -ms-transform: translateY(3px);
    transform: translateY(3px);
}

.option {
    outline: none;
    border-radius: 8px;
    background-color: var(--white-color);
    padding: 0 10px;
    width: 100%;
}

.option:hover {
    color: var(--white-color);
    background-color: var(--primary-color);
}

.main_div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;

    .categories__card {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 30px;
        min-width: 150px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;

        top: 10px;
        position: sticky;

        /* .categories__card__title {
            display: flex;
            height: 40px;
            justify-content: center;
            align-items: center;
            background: var(--primary-color);
            border-radius: 8px 8px 0 0;
            color: var(--white-color);
            width: 100%;
        } */

        .categories__card__list {
            border-radius: 8px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            width: 100%;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: flex-start;
            -ms-flex-align: flex-start;
            align-items: flex-flex-start;
            gap: 5px;
            padding-bottom: 10px;

            .categories__name {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                height: 40px;
                /* justify-content: center; */
                padding-left: 15px;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                width: 100%;
                color: var(--primary-color);
                cursor: pointer;
                text-transform: capitalize;
                background-color: var(--category-background-color);
                border-radius: 10px;
            }

            .categories__name__active {
                background-color: var(--primary-color);
                border-radius: 8px;
                color: var(--white-color);
            }
        }

    }

    /* cart card css */
    .cart__card {
        min-width: 260px;
        border-radius: 8px;

        height: fit-content;

        min-height: 400px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;

        /* justify-content: space-between; */

        position: sticky;
        top: 10px;
        /* max-height: 612px; */
        /* background-color: var(--section-background-color); */

        .header {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            gap: 0px;
            height: 300px;
        }

        .cart__card__title {
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            padding: 16px 32px 15px 23px;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            background: var(--primary-color);
            border-radius: 8px 8px 0 0;
            color: var(--white-color);
            width: 100%;
        }

        .cart__card__products {

            height: 300px;
            overflow-y: scroll;
            background-color: var(--section-background-color);
        }

        .menu__cart__card {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            gap: 20px;
            padding: 6px 8px;

            .card__name__title__price {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                gap: 7px;

                .card__name__title {
                    color: var(--primary-color);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: uppercase;
                    width: 120px;
                }

                .add__card__product {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    gap: 6px;

                    .card__product__price {
                        color: var(--text-white);
                        font-family: Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    .add__and__minus {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        gap: 4px;

                    }
                }
            }

            .menu__cart__card__accessories {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                gap: 6px;
                -webkit-box-align: flex-end;
                -ms-flex-align: flex-end;
                align-items: flex-end;

                .card__name__title__price {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    gap: 6px;
                    width: 200px;

                    .card__name__title {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        gap: 6px;
                        color: var(--text-white);
                        width: 120px;
                    }

                    .add__card__product .card__product__price {
                        color: var(--primary-color);
                    }
                }
            }
        }

    }
}


.plus__or__add {
    border: 1px solid var(--text-white);
    cursor: pointer;
    width: 12px;
    height: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 2px;

    >i {
        font-size: 10px;
        color: var(--text-white);
    }

    .rotated {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}


.menu__cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
}

/* Add this class to create a gap between items in vertical grid */
.menu__cards.vertical-gap {
    gap: 26px;
}

.menu__cards::after {
    content: '';
    position: absolute;
    background-color: var(--white-color);
    opacity: .8;
    display: none;
}

.menu__cards.backdrop::after {
    inset: 0;
    display: block;
}



/* pagination */
.pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.pagination span {
    cursor: pointer;
}

.pagination__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: solid 1px var(--primary-color);
    border-radius: 6px;
}

.active__pagination {
    color: var(--dark-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: var(--primary-color);
    border-radius: 6px;
}

.pagination__arrow {
    color: var(--text-white);
}

.price__on__mobile {
    display: none;
}

.mobile__popup__card {
    z-index: 2;
    margin: 0px;
    border-radius: 8px;
    background: var(--dark-color);
    -webkit-box-shadow: 0px 0px 9.25341px 2.17727px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 9.25341px 2.17727px rgba(0, 0, 0, 0.16);
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-height: 550px;
    position: fixed;
    bottom: 0px;
    right: 0;
    width: auto;


    .cart__card__title {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        padding: 16px 32px 15px 23px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background: var(--primary-color);
        border-radius: 8px 8px 0 0;
        color: var(--white-color);
        width: 100%;

        >i {
            cursor: pointer;
        }
    }


    .cart__card__products {
        height: 150px;
        overflow-y: scroll;
    }

    .menu__cart__card {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 20px;
        padding: 6px 8px;

        .card__name__title__price {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            gap: 7px;

            .card__name__title {
                color: var(--primary-color);
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: uppercase;
                width: 120px;

            }

            .add__card__product {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                gap: 6px;

                .card__product__price {
                    color: var(--secondry-color);
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .add__and__minus {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    gap: 4px;

                }
            }
        }

        .menu__cart__card__accessories {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            gap: 6px;
            -webkit-box-align: flex-end;
            -ms-flex-align: flex-end;
            align-items: flex-end;

            .card__name__title__price {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                gap: 6px;
                width: 230px;

                .card__name__title {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    gap: 6px;
                    color: var(--secondry-color);
                    width: 130px;

                }

                .add__card__product .card__product__price {
                    color: var(--primary-color);
                }
            }
        }
    }

}




.category__filter {
    .btn {
        display: none !important;
    }
}

.cart__card__title {
    >i {
        display: none;
    }
}



@media (max-width:723px) {
    .category__filter {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: flex-start;
        -ms-flex-align: flex-start;
        align-items: flex-start;
        gap: 20px;
    }

    .categories {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: flex-start;
        -ms-flex-align: flex-start;
        align-items: flex-start;
    }
}



/* grid changer */
/* grid changer */
/* grid changer */

.grid__changer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--primary-color);
    background: var(--white-color);

    .grid__icon {
        color: var(--primary-color);
        padding: 8px;
    }

    .active__grid__icon {
        color: var(--white-color);
        background-color: var(--primary-color);
    }

    .active__grid__icon:nth-child(1) {
        border-radius: 7px 0 0 7px;
    }

    .active__grid__icon:nth-child(2) {
        border-radius: 0 7px 7px 0;
    }
}

.cards__category__title {
    color: var(--primary-color);
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2.163px;
}

.card__top__price {
    color: var(--dark-color);
    cursor: pointer;
}

@media (min-width: 1082px) {



    .card__top__price {
        display: none;
        cursor: pointer;
    }
}

@media (max-width: 1082px) {
    .price__on__mobile {
        display: block;
    }

    .cart__card__title {
        height: 60px;
    }

    .mobile__popup__card {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        left: 0;
        bottom: 0px;
    }

    .category__filter::-webkit-scrollbar {
        height: 2px;
    }

    .category__filter {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 6px 0;
        overflow-x: scroll;
        position: static;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        gap: 10px;
    }

    .sort__filter {
    }

    .main_div {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;

        .categories__card,
        .cart__card {
            position: static;
        }

        .categories__card {
            .categories__card__title {
                display: none;
            }
        }


        /* .categories__card {
            max-height: 40px;
            overflow-y: hidden;

            .categories__card__title {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;
                padding: 0 30px;

                >i {
                    font-size: 20px;
                }
            }
        } */

        .cart__card {
            display: none;
        }
    }

    .category__filter {
        .btn {
            display: -webkit-box !important;
            display: -ms-flexbox !important;
            display: flex !important;
        }
    }

    .cart__card__title {
        >i {
            font-size: 30px;
            display: block;
        }
    }

}

@media (max-width:373px) {

    .filter {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
    }

    .options {
        left: 0;
    }

    .category__filter {
        .sort__filter {
            min-width: 160px;
        }
    }
}


.categories__card__list {
    padding: 0 !important;
}

.menu__tab {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 10px;
    padding: 10px;
    width: 100%;
    background-color: var(--section-background-color);
    border-radius: 10px;

    >.tab__name {
        width: 70px;
    }

    >i {
        cursor: pointer;
        font-size: 20px;
        color: var(--primary-color);
    }
}

.comment__cart {
    outline: none;
    padding: 6px;
    border-radius: 10px;
    border: 1px solid var(--primary-color);
    height: 80px;
    width: 100%;
    resize: none;
    max-height: 200px;
    font-size: 14px;
}

.comment__cart::-webkit-input-placeholder {
    font-size: 14px;
}

.comment__cart::-moz-placeholder {
    font-size: 14px;
}

.comment__cart:-ms-input-placeholder {
    font-size: 14px;
}

.comment__cart::-ms-input-placeholder {
    font-size: 14px;
}

.comment__cart::placeholder {
    font-size: 14px;
}

.disable__btn {
    background-color: #999;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1) !important;
}

@media (max-width:714px) {
    .mobile__popup__card {
        bottom: 82px;
    }
}