.cart__items {
    display: flex !important;
    flex-direction: column;
    gap: 24px;

    .sub__heading{
        font-weight: 500;
        color: #666;
        font-size: 28px;
    }
}

.side__cart {
    margin-top: 78px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 12px;

    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

@media (max-width:1252px) {
    .side__cart{
        margin-top: 0;
        width: 100%;
    }
}