.cart__popup {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 99;
    transform: translate(-50%, -50%);

    background: var(--white-color);
    box-shadow: 0 4px 0px 5000px rgba(0, 0, 0, .4);
    width: 440px;
    padding: 10px;
    border-radius: 10px;

    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    .popup__inputs {

        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 10px;

    }
    .btns{
        display: flex;
        gap: 20px;
    }
}