.login__section {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 32px;

  padding: 64px 6vw;

  .login__form {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 24px;

    .login__title {
      font-size: 48px;
      text-align: center;
    }

    .btn {
      width: 100%;
      justify-content: center;
    }

    .form__inputs {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      gap: 24px;

      .input {
        display: flex;
        flex-direction: column;
        width: 100%;

        gap: 6px;

        >label {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        >input {
          padding: 16px;
          border-radius: 10px;

          outline: none;

          border: 1px solid var(--primary-color);
        }
      }

      .relative__input {
        position: relative;
      }

      .checkbox {
        display: flex;
        align-items: center;
        gap: 10px;

        width: 100%;
      }

      .phone__inputField {
        >input {
          padding: 16px 16px 16px 54px;
        }
      }
    }

    .api__btn {
      display: flex;
      align-items: center;
      gap: 24px;

      .facebook__btn,
      .google__button {
        display: flex;
        align-items: center;
        gap: 10px;

        transition: .3s;
        cursor: pointer;

        color: var(--white-color);

        padding: 10px 20px;
        border-radius: 10px;
        background-color: var(--secondary-color);

        >i {
          font-size: 20px;
        }
      }

      .facebook__btn:hover,
      .google__button:hover {
        color: #dddddd;
      }
    }

    .sign__up {
      display: flex;
      align-items: center;
      gap: 4px;

      color: var(--secondary-color);
    }
  }
}

@media (max-width: 1000px) {
  .login__section {
    flex-direction: column-reverse;

    .login__img {
      display: none;
    }
  }
}

@media (max-width: 1000px) {
  .login__section {
    .login__form {
      .api__btn {
        flex-direction: column-reverse;
      }
    }
  }
}