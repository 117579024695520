/* hero__section */
/* hero__section */

.hero__section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: var(--section-padding);
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 90vh;
}

/* hero content */

.hero__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 30px;
}

.sub__title {
    padding: 10px 26px;
    border-radius: 50px;
    background-color: var(--section-background-color);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: var(--bold-font-weight);
    color: var(--secondary-color);
    font-size: var(--medium-size);
}

.searchbar__button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 40px;
}

.search__bar {
    transition: .3s;
    width: 100%;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--section-background-color);
    padding: 6px 6px 6px 14px;
    /* -webkit-box-shadow: 0 4px 10px #FE734D14; */
    /* box-shadow: 0 4px 10px #FE734D14; */
    border-radius: 10px;
}

.search__bar__active {
    -webkit-box-shadow: 0 4px 10px rgba(241, 96, 96, 0.356) !important;
    box-shadow: 0 4px 10px rgba(241, 96, 96, 0.356) !important;
}


.search__bar input {
    min-width: 200px;
    width: 100%;
    outline: none;
    border: 0;
    font-size: var(--link-size);
    font-weight: var(--normal-font-weight);
    color: var(--search-bar-placeholder-color);
    background-color: transparent;
}

.search__bar input::placeholder,
.search__bar input::-moz-placeholder,
.search__bar input:-ms-input-placeholder,
.search__bar input::-webkit-input-placeholder,
.search__bar input::-ms-input-placeholder {
    max-width: 500px;
    font-size: var(--link-size);
    font-weight: var(--normal-font-weight);
    color: var(--search-bar-placeholder-color);
}

.search__bar i {
    cursor: pointer;
    background-color: var(--primary-color);
    border-radius: 10px;
    padding: 10px;
    color: var(--dark-color);
}





/* hero image */


.hero__img {
    max-width: 700px;
}

.hero__img img {
    width: 100%;
}


.mobile {
    display: none;
}






/* services__section */
/* services__section */

.services__section {
    padding: var(--section-padding);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.services__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 20px;
}

.service__subtitle {
    margin: auto;
}

.service__title {
    padding: 20px 0;
    color: var(--secondary-color);
    text-align: center;
    line-height: 56px;
    max-width: 500px;
}

.service__title span {
    font-size: 32px;
}

.services__img {
    max-width: 700px;
}

.services__img img {
    width: 100%;
}










/* menu section */
/* menu section */

.menu__tion {
    padding: var(--section-padding);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    gap: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.menu__title {
    color: var(--secondary-color);
    font-size: 45px;
    max-width: 550px;
}

.home__cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 20px;
}











/* app section */
/* app section */

.app__section {
    background-color: var(--app-section-background-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: var(--section-padding);
    padding: var(--section-padding);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-radius: 10px;
}

.app__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 18px;

    .sub__title {
        background-color: var(--app-section-subtitle-background-color);
    }

    .menu__title {
        font-size: var(--app-section-title-size);
        color: var(--app-section-title-background-color);
    }

    .paragraph {
        font-size: var(--app-section-paragraph-size);
        color: var(--app-section-paragraph-color);
    }
}

.app__image {
    max-width: 500px;
}

.app__image img {
    width: 100%;
}

.contact__section{
    display: flex;
}


@media screen and (max-width: 500px) {
    .contact__section{
        flex-wrap: wrap;

    }
}

.contact__data{
    display: flex;
    flex-direction: column;
    gap: 31px;
    justify-content: center;
    color: var(--dark-color);
    padding: 30px 6vw;
    background-color: var(--primary-color);
    width: 100%;
}


















/* media quries */

@media (max-width:1148px) {


    .title {
        text-align: center;

    }

    .paragraph {
        text-align: center;
    }



    /* hero section */
    /* hero section */

    .hero__section {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 60px 6vw;
    }

    .hero__content {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .hero__img {
        width: 100%;
    }

    .mobile {
        display: block;
    }

    .pc {
        display: none;
    }

    .searchbar__button {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        gap: 20px;
    }


    /* services section */
    /* services section */

    .services__section {
        padding: 60px 6vw;
        gap: 60px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }



    /* menu section */
    /* menu section */

    .menu__tion {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .menu__cards {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }


    /* app section */
    /* app section */

    .app__section {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 30px;
    }

    .app__content {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }


}


/* mobile screen */
/* mobile screen */

@media (max-width:420px) {

    .mobile__popup {
        display: block;
    }

    .title {
        font-size: 32px;
    }

}