.grid__changer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 8px;
    border: 1px solid var(--primary-color);
    background: var(--dark-color);
    cursor: pointer;
    .grid__icon {
        color: var(--primary-color);
        padding: 8px;
    }

    .active__grid__icon {
        color: var(--dark-color);
        background-color: var(--primary-color);
    }

    .active__grid__icon:nth-child(1) {
        border-radius: 7px 0 0 7px;
    }

    .active__grid__icon:nth-child(2) {
        border-radius: 0 7px 7px 0;
    }
}