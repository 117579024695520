/* Snackbar.css */
.reload__snackbar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2); */
  position: fixed;
  bottom: 30px;
  left: 20px;
  background-color: var(--secondary-color);
  color: var(--white-color);
  padding: 16px 32px;
  border-radius: 12px;
  opacity: 0;
  visibility: hidden;
  transition: opacity .8s, visibility .8s, transform .8s;
  transform: translateX(-500px);
  z-index: 2;
  cursor: pointer;
  >div >i,
  >i {
    font-size: 24px;
  }

  >div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    cursor: pointer;
  }
}

.reload__snackbar.reload__snackbar__show {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
  transition: opacity .8s, visibility .8s, transform .8s;
}