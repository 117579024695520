/* SuggestedProduct.css */

/* General styles */
.suggested__popup {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  max-width: 800px;
}

.popup__btns {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;

  .btn {
    justify-content: center;
    width: 100%;
  }

  .sec__btn {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    background-color: transparent;
  }
}

/* Individual card styles */
.suggested__card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;
  padding: 0;
  background-color: var(--dark-color);
  overflow: hidden;

  >img { 
    height: 200px;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    /* Ensures the image fills its container */
  }

  .card__content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .btn {
      background-color: transparent;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      width: 100%;
      justify-content: center;
    }

    .btn:hover {
      background-color: var(--primary-color);
      border: 1px solid var(--primary-color);
      color: var(--dark-color);
    }

    .active__btn {
      background-color: var(--primary-color);
      color: var(--dark-color);

      >i {
        font-size: 20px;
      }
    }
  }
} 
@media (max-width:930px) {
  .suggested__popup {
    max-width: 650px;
  }
}

@media (max-width:750px) {
  .suggested__popup {
    padding: 0px;
    max-width: 450px;
  }
}

@media (max-width:546px) {
  .suggested__popup {
    padding: 0px;
    max-width: 320px;
  }
}