.privacy__page {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 6vw;

    .inner__div {
        border-radius: 10px;

        padding: 24px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    }

    .inner__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;

        .normal__heading {
            width: 100%;

            font-size: 32px;
            font-weight: 600;

            padding-bottom: 12px;

            color: var(--text-color);

            border-bottom: 1px solid var(--text-color);
        }

        .small__heading {
            font-size: 18px;
            font-weight: 500;

            color: var(--text-color);
        }

        >p {
            font-size: 14px;
            color: var(--secondary-color);
        }
    }
}