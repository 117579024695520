.discount__banner {
    background-color: var(--dark-color);
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
    padding: 20px;
    margin: var(--section-padding);
    border-radius: 10px;
    background-color: var(--section-background-color);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .discount__img {
        display: flex;
        align-items: center;
        width: fit-content;

        >img {
            width: 200px;
        }
        .heading{
            color: var(--primary-color);
        }
        .discount__paragraph {
            color: var(--primary-color);
            text-align: flex-start;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

    }

    .discount {
        color: var(--primary-color);
        font-family: Poppins;
        font-size: 102px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        >span:last-child {
            color: var(--primary-color);
            font-family: Poppins;
            font-size: 60px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

}

@media (max-width: 750px) {

    .discount__banner {
        flex-direction: column;
        align-items: center;

        .discount__img {

            .discount__paragraph{
                text-align: center;
                max-width: 100%;
            }

            .heading{
                text-align: center;
            }

            >img {
                display: none;
            }
        }
    }

}