
.payment_card {
  padding: var(--section-padding);
  width: 100%;
  margin: auto;
}

.inner_payment_card {
  width: 100%;
}

#payment-form {
  border: #f6f9fc solid 1px;
  border-radius: var(--radius);
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%);
}

#messages {
  display: none; /* hide initially, then show once the first message arrives */
  background-color: #0a253c;
  color: #00d924;
  padding: 20px;
  margin: 20px 0;
  border-radius: var(--radius);
  font-size: 0.7em;
}

/* .title {
    max-width: 600px;
    font-size: var(--heading-size);
    color: var(--primary-color);
}

.subheading {
    font-size: var(--normal-size);
    color: var(--secondary-color);
    font-weight: var(--normal-font-weight);
}

.paragraph {
    max-width: 500px;
    font-size: var(--paragraph-size);
    font-weight: var(--normal-font-weight);
    color: var(--secondary-color);
}

.btn {
    cursor: pointer;
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 10px 26px;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    transition: var(--transition-3);
    width: fit-content;
    border: 0;
    outline: 0;
}

.btn a {
    color: var(--white-color);
}

.btn:hover {
    box-shadow: var(--shadow-hover);
    font-weight: 500;
}

.underline {
    transition: var(--transition-3) text-decoration;
    color: var(--primary-color);
    font-size: var(--medium-size);
    font-weight: var(--bold-font-weight);
    text-decoration: underline;
    cursor: pointer;
}

button{
    outline: none;
    background-color: transparent;
    border: 0;
} */





