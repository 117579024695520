.address__popup {
    gap: 10px;
    width: 60%;
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 40px;
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    -webkit-box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    background-color: var(--white-color);
    border-radius: 10px;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    -webkit-animation: unipop .3s 1;
    animation: unipop .3s 1;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    visibility: hidden;
}

.address__fields.address__fields {
    width: 100%;
    gap: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.address__popup .address__section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.address__popup .subheading {
    font-size: 32px;
    color: var(--primary-color);
}

@media (max-width:786) {
    .address__popup {
        width: 70%;
    }

    .address__popup .address__fields {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

}