.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-button-prev::after,
.swiper-button-next::after{
  font-weight: 600;
  font-size: 20px;
  color: #333;
}

.swiper-button-prev,
.swiper-button-next{
  border-radius: 50%;
  height: 46px;
  width: 46px;
  background-color: #fff;
}