.checkout__page {

    /* Layout */

    padding: 64px 8vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    .checkout__container {

        /* Layout */

        width: 100%;

        display: flex;
        align-items: start;
        gap: 16px;

        padding: 16px;

        /* Style */

        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 4px 20px 0px rgba(38, 50, 56, 0.10);

        .selected__container {
            width: 100%;

            min-height: 300px;

            display: flex;
            flex-direction: column;
            gap: 16px;

            justify-content: space-between;

            align-items: center;

            .container__heading{
                color: var(--text-color);
            }
            .progress__bar {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;

                width: 100%;

                .progress__number {

                    /* Layout */

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    padding: 12px 20px;
                    border-radius: 50%;

                    /* Style */

                    color: var(--white-color);
                    background-color: var(--text-color,#18303E);
                }

                .progress__line {

                    /* Layout */

                    width: 100%;
                    height: 8px;


                    /* Style */

                    border-radius: 10px;
                    background-color: var(--text-color,#18303E);
                }

                .progress__done {
                    background-color: var(--primary-color);
                }
            }
        }

        .selector__containers {

            /* Layout */

            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 10px;

            width: 100%;

            .radio__selector {

                /* Layout */

                cursor: pointer;

                padding: 28px 24px;

                width: 100%;

                display: flex;
                align-items: center;
                gap: 10px;

                /* Style */

                border-radius: 20px;
                background: var(--primary-extra-light, rgba(191, 57, 57, 0.10));

                .radio__content {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    .radio__heading {
                        color: var(--primary-color, #BF3939);
                        font-family: Raleway;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;

                        line-height: normal;
                    }

                    .radio__description {
                        color: #9AA0B4;
                        font-family: Raleway;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }

            .disabled__selector {
                cursor: not-allowed;

                opacity: .6;
            }
        }

        .selector__containers.row {
            flex-direction: row;
        }

        .btn__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 32px;

            width: 100%;

            .btn {
                display: flex;
                align-items: center;
                gap: 10px;

                padding: 12px 20px;

                >i {
                    transition: .3s;
                    font-size: 22px;
                }
            }

            .right__btn:hover {
                >i {
                    transform: translate(10px);
                }
            }

            .left__btn:hover {
                >i {
                    transform: translate(-10px);
                }
            }

            .btn__opacityLow {

                /* Style */

                opacity: .8;
                background-color: #9AA0B4;
                box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
            }
        }

        .checkout__cart {
            border-radius: 20px;
            background: var(--White-Color, #FFF);
            box-shadow: 0px -4px 16px 0px rgba(38, 50, 56, 0.04);

            overflow: hidden;

            width: 360px;

            .header {
                display: flex;
                flex-direction: column;
                gap: 10px;

                padding: 20px;

                .coupon {
                    color: var(--Text, #18303E);
                    font-family: Raleway;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                    display: flex;
                    align-items: center;

                    >span {
                        color: var(--primary-color);
                    }
                }

                .input__field {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    >input {
                        width: 100%;
                        padding: 5px 0;

                        font-size: 16px;

                        border: 0;
                        border-bottom: 1px solid var(--text-color, #18303E);
                    }

                    .btn {
                        font-size: 14px;

                        padding: 8px;
                    }
                }

                .price__row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    width: 100%;

                    .price__head {
                        color: var(--Text, #18303E);
                        font-family: Raleway;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    .price {
                        color: var(--primary-color);
                        font-family: Montserrat;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }
            }

            .price__total {
                display: flex;
                align-items: center;
                justify-content: space-between;

                background: var(--primary-light, rgba(191, 57, 57, 0.10));

                padding: 16px 20px;

                .price__head {
                    color: var(--text-color, #18303E);
                    font-family: Raleway;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                .price {
                    color: var(--primary-color, #BF3939);
                    font-family: Montserrat;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }
        }
    }
}

@media (max-width:900px) {
    .checkout__page {
        .checkout__container {
            flex-direction: column;

            .checkout__cart {
                width: 100%;
            }

            .selector__containers.row {
                flex-direction: column;
            }

            .selected__container{
                gap: 32px;
                
                .progress__bar{
                    gap: 6px;
                }
            }
        }
    }
}